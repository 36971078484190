import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiError, ListConsumersResponseItem } from "../backend";
import MultiSelect, { OptionGroup } from "../components/MultiSelect";
import Tooltip from "../components/Tooltip";
import { useGlobal } from "../contexts/GlobalContext";

function createGroupedConsumerOptions(consumers: ListConsumersResponseItem[]): OptionGroup[] {
  const groupedConsumers: { [key: string]: ListConsumersResponseItem[] } = {};
  consumers.forEach((consumer) => {
    const group = consumer.group?.name || "Ungrouped";
    if (!groupedConsumers[group]) {
      groupedConsumers[group] = [];
    }
    groupedConsumers[group].push(consumer);
  });
  const groups = Object.entries(groupedConsumers).map(([key, value]) => ({
    label: key,
    options: value.map((consumer) => ({ label: consumer.name, value: consumer.id })),
  }));
  groups.sort((a, b) => {
    if (a.label === "Ungrouped") {
      return -1;
    } else if (b.label === "Ungrouped") {
      return 1;
    } else {
      return a.label.localeCompare(b.label);
    }
  });
  return groups;
}

type ConsumerGroupFormModalProps = {
  consumerGroupId?: number;
  setConsumerGroupId: React.Dispatch<React.SetStateAction<number | undefined>>;
};

interface ConsumerFormValues {
  name: string;
  consumerIds: number[];
}

function ConsumerGroupFormModal({ consumerGroupId, setConsumerGroupId }: ConsumerGroupFormModalProps) {
  const { activeApp, backendClient, isTeamAdmin, isDemo } = useGlobal();
  const [initialFormValues, setInitialFormValues] = useState<ConsumerFormValues>();
  const queryClient = useQueryClient();

  const consumerGroupQueryParams = { appId: activeApp?.id || 0, consumerGroupId: consumerGroupId || 0 };
  const consumerGroupQuery = useQuery({
    queryKey: ["consumerGroup", consumerGroupQueryParams],
    queryFn: () => backendClient!.consumers.getConsumerGroup(consumerGroupQueryParams),
    enabled: !!backendClient && !!activeApp && !!consumerGroupId,
    placeholderData: undefined,
    staleTime: 0,
  });
  const consumersQueryParams = { appId: activeApp?.id || 0 };
  const consumersQuery = useQuery({
    queryKey: ["consumers", consumersQueryParams],
    queryFn: () => backendClient!.consumers.listConsumers(consumersQueryParams),
    enabled: !!backendClient && !!activeApp,
  });
  const consumerOptions = consumersQuery.isSuccess ? createGroupedConsumerOptions(consumersQuery.data) : undefined;

  useEffect(() => {
    if (consumerGroupId === 0) {
      setInitialFormValues({
        name: "",
        consumerIds: [],
      });
    } else if (consumerGroupId && consumerGroupQuery.data) {
      setInitialFormValues({
        name: consumerGroupQuery.data.name,
        consumerIds: consumerGroupQuery.data.consumers.map((consumer) => consumer.id),
      });
    } else {
      setInitialFormValues(undefined);
    }
  }, [consumerGroupId, consumerGroupQuery.data]);

  const validate = async (values: ConsumerFormValues) => {
    const errors: { name?: string; consumerIds?: string } = {};
    if (!values.name || values.name.trim() === "") {
      errors.name = "Required";
    }
    if (values.consumerIds.length === 0) {
      errors.consumerIds = "At least one consumer is required";
    }
    return errors;
  };

  const createOrUpdateConsumerGroup = async (values: ConsumerFormValues) => {
    if (backendClient && activeApp && consumerGroupId !== undefined) {
      if (consumerGroupId === 0) {
        const promise = backendClient.consumers.createConsumerGroup({
          appId: activeApp.id,
          requestBody: {
            name: values.name,
            consumer_ids: values.consumerIds,
          },
        });
        toast.promise(promise, {
          pending: "Creating consumer group...",
          success: "Consumer group created!",
          error: "Failed to create consumer group.",
        });
        await promise;
      } else {
        const promise = backendClient.consumers.updateConsumerGroup({
          appId: activeApp.id,
          consumerGroupId,
          requestBody: {
            name: values.name,
            consumer_ids: values.consumerIds,
          },
        });
        toast.promise(promise, {
          pending: "Updating consumer group...",
          success: "Consumer group updated!",
          error: "Failed to update consumer group.",
        });
        await promise;
      }
    }
  };

  if (!initialFormValues) {
    return <></>;
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validate}
      onSubmit={async (values, { setFieldError, setSubmitting }) => {
        if (!isTeamAdmin) {
          return;
        }
        try {
          await createOrUpdateConsumerGroup(values);
          [
            "consumers",
            "consumersWithTraffic",
            "consumerGroups",
            "consumerGroupsWithTraffic",
            "consumerRequestsSparklines",
          ].forEach((key) => {
            queryClient.invalidateQueries({ queryKey: [key, { appId: activeApp?.id || 0 }] });
          });
          setConsumerGroupId(undefined);
        } catch (e) {
          if (e instanceof ApiError && e.status === 409) {
            setFieldError("name", "A consumer group with this name already exists.");
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values, errors, touched, resetForm }) => (
        <Modal
          className="ConsumerGroupFormModal"
          show={consumerGroupId !== undefined}
          onHide={() => setConsumerGroupId(undefined)}
          onExited={() => resetForm()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{consumerGroupId ? "Edit consumer group" : "Create consumer group"}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <BootstrapForm.Group controlId="formName">
                <BootstrapForm.Label>
                  Name <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <Field name="name">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      type="text"
                      placeholder="Consumer group name"
                      maxLength={64}
                      autoComplete="off"
                      isInvalid={!!errors.name && !!touched.name}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.name}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formConsumers" className="mt-4">
                <BootstrapForm.Label>
                  Consumers <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <FieldArray name="consumerIds">
                  {(arrayHelpers) => (
                    <MultiSelect
                      name={arrayHelpers.name}
                      value={values.consumerIds}
                      options={consumerOptions}
                      placeholder="Select consumers"
                      isSearchable
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      keepInputOnSelect
                    />
                  )}
                </FieldArray>
                <BootstrapForm.Control.Feedback type="invalid">
                  {errors.consumerIds as string}
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setConsumerGroupId(undefined)}>
                Discard
              </Button>
              <Tooltip condition={!isTeamAdmin && isDemo} tooltip="Not allowed in demo">
                <Button type="submit" onClick={() => handleSubmit()} disabled={isSubmitting || !isTeamAdmin}>
                  Submit
                </Button>
              </Tooltip>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default ConsumerGroupFormModal;
