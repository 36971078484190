import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { Field, FieldProps, Form, Formik, FormikErrors, useFormikContext } from "formik";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Offcanvas from "react-bootstrap/Offcanvas";
import Stack from "react-bootstrap/Stack";

import { sortBy } from "lodash";
import Select from "../components/Select";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { CollapsibleOffcanvasBody } from "./CollapsibleOffcanvasBody";
import DebouncedFormControl from "./DebouncedFormControl";

function FormikAutoSubmit() {
  const { isValid, values, dirty, submitForm } = useFormikContext();

  useEffect(() => {
    if (isValid && dirty) {
      submitForm();
    }
  }, [isValid, values, dirty, submitForm]);

  return null;
}

type FilterOffcanvasProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  requestLogFilters?: boolean;
};

function FilterOffcanvas({ show, setShow, requestLogFilters = false }: FilterOffcanvasProps) {
  const { backendClient } = useGlobal();
  const {
    app,
    env,
    consumerId,
    setConsumerId,
    consumerGroupId,
    setConsumerGroupId,
    endpointId,
    setEndpointId,
    endpointGroupId,
    setEndpointGroupId,
    method,
    setMethod,
    statusCode,
    setStatusCode,
    url,
    setUrl,
    minRequestSize,
    maxRequestSize,
    setRequestSizeRange,
    minResponseSize,
    maxResponseSize,
    setResponseSizeRange,
    minResponseTime,
    maxResponseTime,
    setResponseTimeRange,
  } = useFilters();

  const consumersQueryParams = { appId: app?.id || 0, appEnv: env?.slug };
  const { data: consumers } = useQuery({
    queryKey: ["consumers", consumersQueryParams],
    queryFn: () => backendClient!.consumers.listConsumers(consumersQueryParams),
    enabled: !!backendClient && !!app && show,
  });
  const { data: consumerGroups } = useQuery({
    queryKey: ["consumerGroups", consumersQueryParams],
    queryFn: () => backendClient!.consumers.listConsumerGroups(consumersQueryParams),
    enabled: !!backendClient && !!app && show,
  });
  const endpointsQueryParams = { appId: app?.id || 0, appEnvId: env?.id };
  const { data: endpointGroups } = useQuery({
    queryKey: ["endpointGroups", endpointsQueryParams],
    queryFn: () => backendClient!.endpoints.listEndpointGroups(endpointsQueryParams),
    enabled: !!backendClient && !!app && show,
  });
  const { data: endpoints } = useQuery({
    queryKey: ["endpoints", endpointsQueryParams],
    queryFn: () => backendClient!.endpoints.listEndpoints(endpointsQueryParams),
    enabled: !!backendClient && !!app && show && requestLogFilters,
  });
  const { data: statusCodes } = useQuery({
    queryKey: ["requestLogStatusCodeFilterOptions", endpointsQueryParams],
    queryFn: () => backendClient!.requestLog.listStatusCodeFilterOptions(endpointsQueryParams),
    enabled: !!backendClient && !!app && show && requestLogFilters,
  });

  const consumerOptions = consumers?.map((consumer) => ({ label: consumer.name, value: consumer.id })) || [];
  const consumerGroupOptions =
    consumerGroups?.map((consumerGroup) => ({ label: consumerGroup.name, value: consumerGroup.id })) || [];
  const endpointGroupOptions =
    endpointGroups?.map((endpointGroup) => ({ label: endpointGroup.name, value: endpointGroup.id })) || [];
  const endpointOptions =
    endpoints?.map((endpoint) => ({ label: endpoint.method + " " + endpoint.path, value: endpoint.id })) || [];
  const methodOptions = sortBy(
    [...new Set(endpoints?.map((endpoint) => endpoint.method) || [])].map((method) => ({
      label: method,
      value: method,
    })),
    ["label"],
  );
  const statusCodeOptions =
    statusCodes?.map((statusCode) => ({ label: statusCode.toString(), value: statusCode.toString() })) || [];

  return (
    <Formik
      initialValues={{
        consumerId: consumerId || 0,
        consumerGroupId: consumerGroupId || 0,
        endpointId: endpointId || 0,
        endpointGroupId: endpointGroupId || 0,
        method: method || "",
        statusCode: statusCode || "",
        url: url || "",
        minRequestSize: minRequestSize?.toString() || "",
        maxRequestSize: maxRequestSize?.toString() || "",
        minResponseSize: minResponseSize?.toString() || "",
        maxResponseSize: maxResponseSize?.toString() || "",
        minResponseTime: minResponseTime?.toString() || "",
        maxResponseTime: maxResponseTime?.toString() || "",
      }}
      enableReinitialize
      validate={(values) => {
        const errors: FormikErrors<typeof values> = {};
        if (
          values.minRequestSize &&
          values.maxRequestSize &&
          parseFloat(values.minRequestSize) > parseFloat(values.maxRequestSize)
        ) {
          errors.maxRequestSize = "Must be greater than or equal to min request size";
        }
        if (
          values.minResponseSize &&
          values.maxResponseSize &&
          parseFloat(values.minResponseSize) > parseFloat(values.maxResponseSize)
        ) {
          errors.maxResponseSize = "Must be greater than or equal to min response size";
        }
        if (
          values.minResponseTime &&
          values.maxResponseTime &&
          parseInt(values.minResponseTime) > parseInt(values.maxResponseTime)
        ) {
          errors.maxResponseTime = "Must be greater than or equal to min response time";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setConsumerId(values.consumerId || undefined);
          setConsumerGroupId(!values.consumerId && values.consumerGroupId ? values.consumerGroupId : undefined);
          setEndpointId(values.endpointId || undefined);
          setEndpointGroupId(!values.endpointId && values.endpointGroupId ? values.endpointGroupId : undefined);
          setMethod(values.method || undefined);
          setStatusCode(values.statusCode || undefined);
          setUrl(values.url || undefined);
          setRequestSizeRange(
            values.minRequestSize ? parseFloat(values.minRequestSize) : undefined,
            values.maxRequestSize ? parseFloat(values.maxRequestSize) : undefined,
          );
          setResponseSizeRange(
            values.minResponseSize ? parseFloat(values.minResponseSize) : undefined,
            values.maxResponseSize ? parseFloat(values.maxResponseSize) : undefined,
          );
          setResponseTimeRange(
            values.minResponseTime ? parseInt(values.minResponseTime) : undefined,
            values.maxResponseTime ? parseInt(values.maxResponseTime) : undefined,
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleSubmit, resetForm }) => {
        const filterCount =
          (values.consumerId ? 1 : 0) +
          (values.consumerGroupId ? 1 : 0) +
          (values.endpointId ? 1 : 0) +
          (values.endpointGroupId ? 1 : 0) +
          (values.method ? 1 : 0) +
          (values.statusCode ? 1 : 0) +
          (values.url ? 1 : 0) +
          (values.minRequestSize || values.maxRequestSize ? 1 : 0) +
          (values.minResponseSize || values.maxResponseSize ? 1 : 0) +
          (values.minResponseTime || values.maxResponseTime ? 1 : 0);

        return (
          <Offcanvas
            placement="end"
            backdrop
            scroll
            show={show}
            onHide={() => setShow(false)}
            onExited={() => resetForm()}
          >
            <Offcanvas.Header closeButton>
              <div className="offcanvas-title h4">Filters</div>
            </Offcanvas.Header>
            <Offcanvas.Header className="pt-0 small">
              <Button
                variant="link"
                onClick={() => {
                  setConsumerId(undefined);
                  setConsumerGroupId(undefined);
                  setEndpointId(undefined);
                  setEndpointGroupId(undefined);
                  setMethod(undefined);
                  setStatusCode(undefined);
                  setUrl(undefined);
                  setRequestSizeRange(undefined, undefined);
                  setResponseSizeRange(undefined, undefined);
                  setResponseTimeRange(undefined, undefined);
                }}
                disabled={filterCount === 0}
              >
                <FontAwesomeIcon icon={faXmark} className="me-icon" />
                Clear all
              </Button>
            </Offcanvas.Header>
            <FormikAutoSubmit />
            <Form onSubmit={handleSubmit}>
              <CollapsibleOffcanvasBody title="Common">
                <BootstrapForm.Group controlId="formConsumer">
                  <BootstrapForm.Label>Consumer</BootstrapForm.Label>
                  <Field name="consumerId">
                    {({ field }: FieldProps<number>) => (
                      <Select
                        options={consumerOptions}
                        placeholder="All consumers"
                        isSearchable
                        isClearable
                        {...field}
                      />
                    )}
                  </Field>
                </BootstrapForm.Group>
                <BootstrapForm.Group controlId="formConsumerGroup" className="mt-4">
                  <BootstrapForm.Label>Consumer group</BootstrapForm.Label>
                  <Field name="consumerGroupId">
                    {({ field }: FieldProps<number>) => (
                      <Select
                        options={consumerGroupOptions}
                        placeholder="All consumer groups"
                        isSearchable
                        isClearable
                        {...field}
                      />
                    )}
                  </Field>
                </BootstrapForm.Group>
                <BootstrapForm.Group controlId="formEndpointGroup" className="mt-4">
                  <BootstrapForm.Label>Endpoint group</BootstrapForm.Label>
                  <Field name="endpointGroupId">
                    {({ field }: FieldProps<number>) => (
                      <Select
                        options={endpointGroupOptions}
                        placeholder="All endpoints"
                        isSearchable
                        isClearable
                        {...field}
                      />
                    )}
                  </Field>
                </BootstrapForm.Group>
                <div className="very-small text-very-muted mt-4">
                  The above filters are persisted between dashboards.
                </div>
              </CollapsibleOffcanvasBody>
              {requestLogFilters && (
                <CollapsibleOffcanvasBody title="Request log">
                  <BootstrapForm.Group controlId="formUrl">
                    <BootstrapForm.Label>URL</BootstrapForm.Label>
                    <Field name="url">
                      {({ field }: FieldProps<string>) => {
                        return <DebouncedFormControl placeholder="All URLs" {...field} />;
                      }}
                    </Field>
                  </BootstrapForm.Group>
                  <BootstrapForm.Group controlId="formEndpoint" className="mt-4">
                    <BootstrapForm.Label>Endpoint</BootstrapForm.Label>
                    <Field name="endpointId">
                      {({ field }: FieldProps<number>) => (
                        <Select
                          options={endpointOptions}
                          placeholder="All endpoints"
                          isSearchable
                          isClearable
                          {...field}
                        />
                      )}
                    </Field>
                  </BootstrapForm.Group>
                  <BootstrapForm.Group controlId="formMethod" className="mt-4">
                    <BootstrapForm.Label>Method</BootstrapForm.Label>
                    <Field name="method">
                      {({ field }: FieldProps<string>) => (
                        <Select<string, true>
                          options={methodOptions}
                          placeholder="All methods"
                          isSearchable
                          isClearable
                          isMulti
                          {...field}
                        />
                      )}
                    </Field>
                  </BootstrapForm.Group>
                  <BootstrapForm.Group controlId="formStatusCode" className="mt-4">
                    <BootstrapForm.Label>Status code</BootstrapForm.Label>
                    <Field name="statusCode">
                      {({ field }: FieldProps<string>) => (
                        <Select<string, true>
                          options={statusCodeOptions}
                          placeholder="All status codes"
                          isSearchable
                          isClearable
                          isMulti
                          {...field}
                        />
                      )}
                    </Field>
                  </BootstrapForm.Group>
                  <BootstrapForm.Group controlId="formRequestSize" className="mt-4">
                    <BootstrapForm.Label>Request size</BootstrapForm.Label>
                    <Stack direction="horizontal">
                      <InputGroup>
                        <Field name="minRequestSize">
                          {({ field }: FieldProps<string>) => (
                            <DebouncedFormControl
                              type="number"
                              placeholder="0"
                              min={0}
                              max={60000}
                              step={1}
                              isInvalid={!!errors.minRequestSize && !!touched.minRequestSize}
                              {...field}
                            />
                          )}
                        </Field>
                        <InputGroup.Text className="text-secondary">KB</InputGroup.Text>
                      </InputGroup>
                      <div className="px-3">-</div>
                      <InputGroup>
                        <Field name="maxRequestSize">
                          {({ field }: FieldProps<string>) => (
                            <DebouncedFormControl
                              type="number"
                              placeholder="∞"
                              min={0}
                              max={60000}
                              step={1}
                              isInvalid={!!errors.maxRequestSize && !!touched.maxRequestSize}
                              {...field}
                            />
                          )}
                        </Field>
                        <InputGroup.Text className="text-secondary">KB</InputGroup.Text>
                      </InputGroup>
                    </Stack>
                  </BootstrapForm.Group>
                  <BootstrapForm.Group controlId="formResponseSize" className="mt-4">
                    <BootstrapForm.Label>Response size</BootstrapForm.Label>
                    <Stack direction="horizontal">
                      <InputGroup>
                        <Field name="minResponseSize">
                          {({ field }: FieldProps<string>) => (
                            <DebouncedFormControl
                              type="number"
                              placeholder="0"
                              min={0}
                              max={60000}
                              step={1}
                              isInvalid={!!errors.minResponseSize && !!touched.minResponseSize}
                              {...field}
                            />
                          )}
                        </Field>
                        <InputGroup.Text className="text-secondary">KB</InputGroup.Text>
                      </InputGroup>
                      <div className="px-3">-</div>
                      <InputGroup>
                        <Field name="maxResponseSize">
                          {({ field }: FieldProps<string>) => (
                            <DebouncedFormControl
                              type="number"
                              placeholder="∞"
                              min={0}
                              max={60000}
                              step={1}
                              isInvalid={!!errors.maxResponseSize && !!touched.maxResponseSize}
                              {...field}
                            />
                          )}
                        </Field>
                        <InputGroup.Text className="text-secondary">KB</InputGroup.Text>
                      </InputGroup>
                    </Stack>
                  </BootstrapForm.Group>
                  <BootstrapForm.Group controlId="formResponseTime" className="mt-4">
                    <BootstrapForm.Label>Response time</BootstrapForm.Label>
                    <Stack direction="horizontal">
                      <InputGroup>
                        <Field name="minResponseTime">
                          {({ field }: FieldProps<string>) => (
                            <DebouncedFormControl
                              type="number"
                              placeholder="0"
                              min={0}
                              max={60000}
                              step={1}
                              isInvalid={!!errors.minResponseTime && !!touched.minResponseTime}
                              {...field}
                            />
                          )}
                        </Field>
                        <InputGroup.Text className="text-secondary">ms</InputGroup.Text>
                      </InputGroup>
                      <div className="px-3">-</div>
                      <InputGroup>
                        <Field name="maxResponseTime">
                          {({ field }: FieldProps<string>) => (
                            <DebouncedFormControl
                              type="number"
                              placeholder="∞"
                              min={0}
                              max={60000}
                              step={1}
                              isInvalid={!!errors.maxResponseTime && !!touched.maxResponseTime}
                              {...field}
                            />
                          )}
                        </Field>
                        <InputGroup.Text className="text-secondary">ms</InputGroup.Text>
                      </InputGroup>
                    </Stack>
                  </BootstrapForm.Group>
                </CollapsibleOffcanvasBody>
              )}
            </Form>
          </Offcanvas>
        );
      }}
    </Formik>
  );
}

export default FilterOffcanvas;
