import { AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import ChangePasswordRedirect from "../components/ChangePasswordRedirect";
import PageSpinner from "../components/PageSpinner";

function Auth0LoginRedirect({ children }: { children: React.ReactNode }) {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const login = async () => {
    await loginWithRedirect({
      appState: { returnTo: location.pathname + location.search },
    });
  };

  if (!isLoading && !isAuthenticated) {
    login();
  }
  if (isLoading || !isAuthenticated) {
    return <PageSpinner />;
  }

  return <>{children}</>;
}

function Auth0ProviderWithNavigate({ children }: { children: React.ReactNode }) {
  const [changePassword, setChangePassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const signup = searchParams.has("signup");

  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
  const redirectUri = import.meta.env.VITE_AUTH0_CALLBACK_URL;

  const onRedirectCallback = (appState?: AppState) => {
    if (appState?.changePassword) {
      setChangePassword(true);
    } else {
      const returnTo = appState?.returnTo || location.pathname + location.search;
      navigate(returnTo, { replace: true });
    }
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
        screen_hint: signup ? "signup" : "login",
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      {changePassword && <ChangePasswordRedirect />}
      {!changePassword && <Auth0LoginRedirect>{children}</Auth0LoginRedirect>}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithNavigate;
