import { useAuth0 } from "@auth0/auth0-react";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleRight,
  faBell,
  faBoltLightning,
  faBug,
  faBullseye,
  faChartSimple,
  faCircle,
  faCog,
  faCogs,
  faCommentDots,
  faFingerprint,
  faHeartPulse,
  faHouse,
  faLifeRing,
  faScroll,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import sumBy from "lodash/sumBy";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, NavLink } from "react-router-dom";

import { getNewChangelogItemCount } from "../changelog/utils";
import { useGlobal } from "../contexts/GlobalContext";
import LightLogo from "../static/logo-light.svg?react";
import CustomIcon from "./CustomIcon";
import "./Sidebar.css";
import UserDropdownMenu from "./UserDropdownMenu";
import UserImage from "./UserImage";

type SidebarProps = {
  setShowChangelogModal: (show: boolean) => void;
  setShowFeedbackModal: (show: boolean) => void;
};

function Sidebar({ setShowChangelogModal, setShowFeedbackModal }: SidebarProps) {
  const { user } = useAuth0();
  const { teams, alertCounts, activeTeam, setActiveTeam, isSiteAdmin } = useGlobal();
  const activeAlertCount = sumBy(Object.values(alertCounts || {}), "active_not_muted");
  const newChangelogItemCount = getNewChangelogItemCount();

  const exitDemo = () => {
    if (activeTeam?.demo) {
      const otherTeam = teams?.find((team) => !team.demo);
      setActiveTeam(otherTeam);
    }
  };

  return (
    <>
      <SidebarMenu className="Sidebar overflow-auto" hide="md" expand="xl">
        <SidebarMenu.Collapse className="h-100">
          <div className="h-100 d-flex flex-column">
            <SidebarMenu.Header>
              <Link to="/apps">
                <LightLogo width={undefined} height={undefined} className="logo" />
              </Link>
            </SidebarMenu.Header>
            <SidebarMenu.Body className="flex-fill d-flex flex-column">
              <SidebarMenu.Nav>
                <NavLink to="/apps" className="sidebar-menu-nav-link" title="Apps" tabIndex={0}>
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon icon={faHouse} />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Apps</SidebarMenu.Nav.Title>
                </NavLink>
                <NavLink to="/traffic" className="sidebar-menu-nav-link" title="Traffic" tabIndex={2}>
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon icon={faChartSimple} />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Traffic</SidebarMenu.Nav.Title>
                </NavLink>
                <NavLink to="/errors" className="sidebar-menu-nav-link" title="Errors" tabIndex={3}>
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon icon={faBug} />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Errors</SidebarMenu.Nav.Title>
                </NavLink>
                <NavLink to="/performance" className="sidebar-menu-nav-link" title="Performance" tabIndex={4}>
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon icon={faBoltLightning} />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Performance</SidebarMenu.Nav.Title>
                </NavLink>
                <NavLink to="/consumers" className="sidebar-menu-nav-link" title="Consumers" tabIndex={5}>
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon icon={faFingerprint} />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Consumers</SidebarMenu.Nav.Title>
                </NavLink>
                <NavLink to="/endpoint-groups" className="sidebar-menu-nav-link" title="Endpoints" tabIndex={6}>
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon icon={faBullseye} />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Endpoints</SidebarMenu.Nav.Title>
                </NavLink>
                <NavLink to="/request-log" className="sidebar-menu-nav-link" title="Request log" tabIndex={1}>
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon icon={faScroll} />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Request log</SidebarMenu.Nav.Title>
                </NavLink>
                <NavLink to="/uptime" className="sidebar-menu-nav-link" title="Uptime" tabIndex={7}>
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon icon={faHeartPulse} />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Uptime</SidebarMenu.Nav.Title>
                </NavLink>
                <NavLink to="/alerts" className="sidebar-menu-nav-link" title="Alerts" tabIndex={8}>
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon
                      icon={faBell}
                      className={classNames({ "d-none d-xl-inline-block": activeAlertCount > 0 })}
                    />
                    <span
                      className={classNames("fa-layers", {
                        "d-none": activeAlertCount === 0,
                        "d-xl-none": activeAlertCount > 0,
                      })}
                    >
                      <FontAwesomeIcon icon={faCircle} mask={faBell} transform="shrink-4 right-6 down-6" />
                      <FontAwesomeIcon icon={faCircle} transform="shrink-8 right-6 down-6" className="text-danger" />
                    </span>
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Alerts</SidebarMenu.Nav.Title>
                  {activeAlertCount > 0 && (
                    <div className="ms-auto d-none d-xl-block">
                      <Badge bg="danger" pill>
                        {activeAlertCount}
                      </Badge>
                    </div>
                  )}
                </NavLink>
                {activeTeam?.demo && (
                  <SidebarMenu.Nav.Link
                    className="exit-demo mt-4"
                    title="Exit demo"
                    tabIndex={13}
                    onClick={() => exitDemo()}
                  >
                    <SidebarMenu.Nav.Icon>
                      <FontAwesomeIcon icon={faXmark} className="text-danger" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Exit demo</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                )}
              </SidebarMenu.Nav>
              <SidebarMenu.Nav className="mt-auto">
                <SidebarMenu.Nav.Link
                  title="What's new"
                  tabIndex={10}
                  onClick={() => setShowChangelogModal(true)}
                  active={false}
                >
                  <SidebarMenu.Nav.Icon>
                    <CustomIcon src="/icons/sparkles-solid.svg" />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>What's new</SidebarMenu.Nav.Title>
                  {newChangelogItemCount > 0 && (
                    <div className="ms-auto">
                      <Badge bg="secondary" pill>
                        {newChangelogItemCount < 9 ? newChangelogItemCount : 9}
                      </Badge>
                    </div>
                  )}
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link
                  href="https://docs.apitally.io/support"
                  target="_blank"
                  title="Support"
                  tabIndex={9}
                  active={false}
                >
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon icon={faLifeRing} />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Support</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link
                  title="Feedback"
                  tabIndex={10}
                  onClick={() => setShowFeedbackModal(true)}
                  active={false}
                >
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon icon={faCommentDots} />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Feedback</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <NavLink to="/settings" className="sidebar-menu-nav-link" title="Settings" tabIndex={11}>
                  <SidebarMenu.Nav.Icon>
                    <FontAwesomeIcon icon={faCog} />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Settings</SidebarMenu.Nav.Title>
                </NavLink>
                {isSiteAdmin && (
                  <NavLink to="/admin/teams" className="sidebar-menu-nav-link" title="Admin" tabIndex={12}>
                    <SidebarMenu.Nav.Icon>
                      <FontAwesomeIcon icon={faCogs} />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Admin</SidebarMenu.Nav.Title>
                  </NavLink>
                )}
              </SidebarMenu.Nav>
            </SidebarMenu.Body>
            <div className="border-top border-bottom">
              <Dropdown drop="end" className="no-caret">
                <Dropdown.Toggle tabIndex={9} className="w-100">
                  <div className="d-flex flex-row align-items-center justify-content-center justify-content-xl-between p-1 gap-3">
                    <UserImage user={user} variant="light" className="user-image" />
                    <div className="user-name-team d-none d-xl-block">
                      <div className="small fw-bold text-truncate">{user?.name}</div>
                      <div className="very-small text-truncate">{activeTeam?.name}</div>
                    </div>
                    <div className="ms-auto d-none d-xl-block">
                      <FontAwesomeIcon icon={faAngleRight} className="text-very-muted" />
                    </div>
                  </div>
                </Dropdown.Toggle>
                <UserDropdownMenu align="end" renderOnMount popperConfig={{ strategy: "fixed" }} />
              </Dropdown>
            </div>
            <SidebarMenu.Footer className="text-center mt-2 py-2 d-none d-xl-block">
              <span className="very-small text-very-muted">
                Made with <FontAwesomeIcon icon={faHeart} /> in Australia
              </span>
            </SidebarMenu.Footer>
          </div>
        </SidebarMenu.Collapse>
      </SidebarMenu>
    </>
  );
}

export default Sidebar;
