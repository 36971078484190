import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dedent from "dedent";
import React, { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";

import "./AppSetupInstructions.css";
import { AppEnvItem, ListAppsResponseItem } from "./backend";
import AppDropdown from "./components/AppDropdown";
import CodeBlock from "./components/CodeBlock";
import CodeBlockGroup from "./components/CodeBlockGroup";
import CopyToClipboardWithTooltip from "./components/CopyToClipboardWithTooltip";
import MainLayout from "./components/MainLayout";
import PageHeader from "./components/PageHeader";
import PageSpinner from "./components/PageSpinner";
import { useGlobal } from "./contexts/GlobalContext";

const getEnvParam = (envs: AppEnvItem[], language: "python" | "javascript") => {
  const firstEnvSlug = envs[0].slug;
  const otherEnvSlugsQuoted = envs
    .slice(1)
    .map((env) => `"${env.slug}"`)
    .join(", ");
  const value = `"${firstEnvSlug}",`;
  let comment = "";
  if (language === "python") {
    comment = otherEnvSlugsQuoted ? `  # or ${otherEnvSlugsQuoted}` : "";
  } else if (language === "javascript") {
    comment = otherEnvSlugsQuoted ? ` // or ${otherEnvSlugsQuoted}` : "";
  }
  return value + comment;
};

const getStep1ForPython = (framework: string) => {
  return (
    <>
      Install the{" "}
      <a href="https://pypi.org/project/apitally/" target="_blank" rel="noreferrer">
        Apitally client library for Python
      </a>{" "}
      with the required extra for {framework} in your project.
    </>
  );
};

const getStep1ForNodeJs = (extras?: string) => {
  return (
    <>
      Install the{" "}
      <a href="https://www.npmjs.com/package/apitally" target="_blank" rel="noreferrer">
        Apitally client library for Node.js
      </a>{" "}
      {extras ? extras + " " : ""}
      in your project.
    </>
  );
};

const getStep2ForPython = (framework: string, order: string = "after") => {
  return (
    <>
      Add the Apitally middleware to your {framework} application {order} any other middleware.
    </>
  );
};

const getStep2ForNodeJs = (framework: string) => {
  return <>Add the Apitally middleware to your {framework} application before any other middleware.</>;
};

type InstructionRecord = {
  language: string;
  step1: React.ReactNode;
  installCode: string;
  step2: React.ReactNode;
  addMiddlewareCode: string | Record<string, string>;
  docsLink: string;
};

const getInstructionData = (app: ListAppsResponseItem): InstructionRecord | undefined => {
  if (app.framework === "Django Ninja") {
    return {
      language: "python",
      step1: getStep1ForPython("Django Ninja"),
      installCode: 'pip install "apitally[django_ninja]"',
      step2: getStep2ForPython("Django Ninja", "before"),
      addMiddlewareCode: dedent`
        # Django settings file (settings.py)
        MIDDLEWARE = [
          "apitally.django_ninja.ApitallyMiddleware",
          # Other middleware ...
        ]
        APITALLY_MIDDLEWARE = {
            "client_id": "your-client-id",
            "env": ${getEnvParam(app.envs, "python")}
        }
      `,
      docsLink: "https://docs.apitally.io/frameworks/django-ninja",
    };
  }

  if (app.framework === "Django REST Framework") {
    return {
      language: "python",
      step1: getStep1ForPython("Django REST Framework"),
      installCode: 'pip install "apitally[django_rest_framework]"',
      step2: getStep2ForPython("Django REST Framework", "before"),
      addMiddlewareCode: dedent`
        # Django settings file (settings.py)
        MIDDLEWARE = [
            "apitally.django_rest_framework.ApitallyMiddleware",
            # Other middleware ...
        ]
        APITALLY_MIDDLEWARE = {
            "client_id": "your-client-id",
            "env": ${getEnvParam(app.envs, "python")}
        }
      `,
      docsLink: "https://docs.apitally.io/frameworks/django-rest-framework",
    };
  }

  if (app.framework === "Express") {
    return {
      language: "javascript",
      step1: getStep1ForNodeJs(),
      installCode: "npm install apitally",
      step2: (
        <>Add the Apitally middleware to your Express application before the route handlers and any other middleware.</>
      ),
      addMiddlewareCode: {
        ESM: dedent`
          import express from "express";
          import { useApitally } from "apitally/express";

          const app = express();
          app.use(express.json());

          useApitally(app, {
            clientId: "your-client-id",
            env: ${getEnvParam(app.envs, "javascript")}
          });

          // Ensure route handlers are registered after useApitally()
        `,
        CommonJS: dedent`
          const express = require("express");
          const { useApitally } = require("apitally/express");

          const app = express();
          app.use(express.json());

          useApitally(app, {
            clientId: "your-client-id",
            env: ${getEnvParam(app.envs, "javascript")}
          });

          // Ensure route handlers are registered after useApitally()
        `,
      },
      docsLink: "https://docs.apitally.io/frameworks/express",
    };
  }

  if (app.framework === "FastAPI") {
    return {
      language: "python",
      step1: getStep1ForPython("FastAPI"),
      installCode: 'pip install "apitally[fastapi]"',
      step2: getStep2ForPython("FastAPI"),
      addMiddlewareCode: dedent`
        from fastapi import FastAPI
        from apitally.fastapi import ApitallyMiddleware

        app = FastAPI()
        app.add_middleware(
            ApitallyMiddleware,
            client_id="your-client-id",
            env=${getEnvParam(app.envs, "python")}
        )
      `,
      docsLink: "https://docs.apitally.io/frameworks/fastapi",
    };
  }

  if (app.framework === "Fastify") {
    return {
      language: "javascript",
      step1: getStep1ForNodeJs("and the fastify-plugin package"),
      installCode: "npm install apitally fastify-plugin",
      step2: "Register the Apitally plugin with your Fastify application (before any other plugins).",
      addMiddlewareCode: {
        ESM: dedent`
          import Fastify from "fastify";
          import { apitallyPlugin } from "apitally/fastify";

          const fastify = Fastify({ logger: true });

          await fastify.register(apitallyPlugin, {
            clientId: "your-client-id",
            env: ${getEnvParam(app.envs, "javascript")}
          });
        `,
        CommonJS: dedent`
          const fastify = require("fastify")({ logger: true });
          const { apitallyPlugin } = require("apitally/fastify");

          fastify.register(apitallyPlugin, {
            clientId: "your-client-id",
            env: ${getEnvParam(app.envs, "javascript")}
          });

          // Wrap your routes in a plugin, so Apitally can detect them
          fastify.register((instance, opts, done) => {
            instance.get("/", (request, reply) => {
              reply.send("hello");
            });
            done();
          });
        `,
      },
      docsLink: "https://docs.apitally.io/frameworks/fastify",
    };
  }

  if (app.framework === "Flask") {
    return {
      language: "python",
      step1: getStep1ForPython("Flask"),
      installCode: 'pip install "apitally[flask]"',
      step2: getStep2ForPython("Flask"),
      addMiddlewareCode: dedent`
        from flask import Flask
        from apitally.flask import ApitallyMiddleware

        app = Flask(__name__)
        app.wsgi_app = ApitallyMiddleware(
            app,
            client_id="your-client-id",
            env=${getEnvParam(app.envs, "python")}
        )
      `,
      docsLink: "https://docs.apitally.io/frameworks/flask",
    };
  }

  if (app.framework === "Hono") {
    return {
      language: "javascript",
      step1: getStep1ForNodeJs(),
      installCode: "npm install apitally",
      step2: (
        <>Add the Apitally middleware to your Hono application before the route handlers and any other middleware.</>
      ),
      addMiddlewareCode: {
        ESM: dedent`
          import { Hono } from "hono";
          import { useApitally } from "apitally/hono";

          const app = new Hono();

          useApitally(app, {
            clientId: "your-client-id",
            env: ${getEnvParam(app.envs, "javascript")}
          });

          // Ensure route handlers are registered after useApitally()
        `,
      },
      docsLink: "https://docs.apitally.io/frameworks/hono",
    };
  }

  if (app.framework === "Koa") {
    return {
      language: "javascript",
      step1: getStep1ForNodeJs(),
      installCode: "npm install apitally",
      step2: (
        <>Add the Apitally middleware to your Koa application before the route handlers and any other middleware.</>
      ),
      addMiddlewareCode: {
        ESM: dedent`
          import Koa from "koa";
          import { useApitally } from "apitally/koa";

          const app = new Koa();

          useApitally(app, {
            clientId: "your-client-id",
            env: ${getEnvParam(app.envs, "javascript")}
          });

          // Ensure route handlers are registered after useApitally()
        `,
        CommonJS: dedent`
          const Koa = require("koa");
          const { useApitally } = require("apitally/koa");

          const app = new Koa();

          useApitally(app, {
            clientId: "your-client-id",
            env: ${getEnvParam(app.envs, "javascript")}
          });

          // Ensure route handlers are registered after useApitally()
        `,
      },
      docsLink: "https://docs.apitally.io/frameworks/koa",
    };
  }

  if (app.framework === "Litestar") {
    return {
      language: "python",
      step1: getStep1ForPython("Litestar"),
      installCode: 'pip install "apitally[litestar]"',
      step2: "Add the Apitally plugin to your Litestar application (after any other plugin).",
      addMiddlewareCode: dedent`
        from litestar import Litestar
        from apitally.litestar import ApitallyPlugin

        apitally_plugin = ApitallyPlugin(
            client_id="your-client-id",
            env=${getEnvParam(app.envs, "python")}
        )
        app = Litestar(route_handlers=[...], plugins=[apitally_plugin])
      `,
      docsLink: "https://docs.apitally.io/frameworks/litestar",
    };
  }

  if (app.framework === "NestJS") {
    return {
      language: "javascript",
      step1: getStep1ForNodeJs(),
      installCode: "npm install apitally",
      step2: getStep2ForNodeJs("NestJS"),
      addMiddlewareCode: {
        ESM: dedent`
          import { NestFactory } from "@nestjs/core";
          import { useApitally } from "apitally/nestjs";
          import { AppModule } from "./app.module";

          const app = await NestFactory.create(AppModule);

          useApitally(app, {
            clientId: "your-client-id",
            env: ${getEnvParam(app.envs, "javascript")}
          });
        `,
        CommonJS: dedent`
          const { NestFactory } = require("@nestjs/core");
          const { useApitally } = require("apitally/nestjs");
          const { AppModule } = require("./app.module");

          const app = await NestFactory.create(AppModule);

          useApitally(app, {
            clientId: "your-client-id",
            env: ${getEnvParam(app.envs, "javascript")}
          });
        `,
      },
      docsLink: "https://docs.apitally.io/frameworks/nestjs",
    };
  }

  if (app.framework === "Starlette") {
    return {
      language: "python",
      step1: getStep1ForPython("Starlette"),
      installCode: 'pip install "apitally[starlette]"',
      step2: getStep2ForPython("Starlette"),
      addMiddlewareCode: dedent`
      from starlette.application import Starlette
      from apitally.starlette import ApitallyMiddleware

      app = Starlette(routes=[...])
      app.add_middleware(
          ApitallyMiddleware,
          client_id="your-client-id",
          env=${getEnvParam(app.envs, "python")}
      )
    `,
      docsLink: "https://docs.apitally.io/frameworks/starlette",
    };
  }
};

function InstructionSteps({ app }: { app: ListAppsResponseItem }) {
  const data = getInstructionData(app);
  if (!data) {
    return <></>;
  }
  return (
    <ol className="stepper mt-6 mb-2">
      <li>
        {data.step1}
        <CodeBlock language="bash">{data.installCode.trim()}</CodeBlock>
      </li>
      <li>
        {data.step2}
        {typeof data.addMiddlewareCode === "string" ? (
          <CodeBlock language={data.language}>
            {data.addMiddlewareCode.replace("your-client-id", app.client_id).trim()}
          </CodeBlock>
        ) : (
          <CodeBlockGroup>
            {Object.entries(data.addMiddlewareCode).map(([title, code]) => (
              <CodeBlock key={title} title={title} language={data.language}>
                {code.replace("your-client-id", app.client_id).trim()}
              </CodeBlock>
            ))}
          </CodeBlockGroup>
        )}
      </li>
      <li>
        Deploy your application, or restart it if you're testing locally.
        <Alert variant="light" className="mt-4 py-2">
          <FontAwesomeIcon
            icon={faCheck}
            className="text-primary"
            style={{ position: "absolute", left: "1em", top: "0.625em" }}
          />
          <div className="small text-muted" style={{ marginLeft: "2em" }}>
            <p className="mb-2">The basic setup for your application is now complete.</p>
            <p className="mb-0">
              Check out our more detailed{" "}
              <a href={data.docsLink} target="_blank">
                setup guide
              </a>{" "}
              to learn how to identify API consumers or configure request logging in your application.
            </p>
          </div>
        </Alert>
      </li>
      <li>
        Navigate to the Traffic dashboard and see it come to life with data from your application.
        <div className="mt-4">
          <Link to={`/traffic/${app.slug}`}>
            <Button>Continue to dashboard</Button>
          </Link>
        </div>
      </li>
    </ol>
  );
}

function AppSetupInstructions() {
  const { appSlug } = useParams();
  const { apps } = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "App setup instructions - Apitally";
  }, []);

  if (!apps) {
    return (
      <MainLayout>
        <PageSpinner />
      </MainLayout>
    );
  }

  const setupApp = apps.find((app) => app.slug === appSlug);

  if (!setupApp) {
    return (
      <MainLayout>
        <Navigate to="/apps" />
      </MainLayout>
    );
  }

  const pageHeaderButton = (
    <Button variant="link" className="float-end" onClick={() => navigate("/apps")}>
      <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
      Back to apps
    </Button>
  );
  const pageHeaderDropdown = (
    <Dropdown.Menu>
      <Dropdown.Item as="button" onClick={() => navigate("/apps")}>
        <FontAwesomeIcon icon={faArrowLeft} fixedWidth />
        Back to apps
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  return (
    <MainLayout>
      <div className="AppSetupInstructions">
        <PageHeader buttons={pageHeaderButton} dropdownMenu={pageHeaderDropdown}>
          <>App setup instructions</>
          <AppDropdown currentApp={setupApp} onSelect={(key) => key !== null && navigate(`/apps/${key}/setup`)} />
        </PageHeader>
        <Card className="bt">
          <Card.Body>
            <div className="pb-4 mb-6 border-bottom">
              <div>
                Your app has been created! The next step is to configure your application to work with Apitally. Simply
                follow the steps below.
              </div>
              <div className="mt-1">
                The <b>client ID</b> for this app is{" "}
                <CopyToClipboardWithTooltip text={setupApp.client_id}>
                  <code className="mx-1 cursor-pointer">
                    <FontAwesomeIcon icon={faCopy} className="me-1" />
                    {setupApp.client_id}
                  </code>
                </CopyToClipboardWithTooltip>
                .
              </div>
            </div>
            <InstructionSteps app={setupApp} />
          </Card.Body>
        </Card>
      </div>
    </MainLayout>
  );
}

export default AppSetupInstructions;
