import { FieldInputProps, useFormikContext } from "formik";
import ReactSelect, {
  GroupBase,
  MultiValue,
  OnChangeValue,
  PropsValue,
  SingleValue,
  StylesConfig,
  ThemeConfig,
} from "react-select";
import CreatableReactSelect, { CreatableProps } from "react-select/creatable";

export type Option<T = number> = { value: T; label: string };
export declare type OptionGroup<T = number> = GroupBase<Option<T>>;

export function getSelectStyles<OptionType, IsMulti extends boolean, Group extends GroupBase<OptionType>>() {
  const styles: StylesConfig<OptionType, IsMulti, Group> = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      "borderColor": state.isFocused ? "#85cbcb" : "var(--bs-border-color)",
      "boxShadow": state.isFocused ? "0 0 0 0.25rem rgba(11, 151, 151, 0.25)" : "none",
      "&:hover": {
        borderColor: undefined,
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      "backgroundColor": state.isSelected ? "var(--bs-primary)" : state.isFocused ? "#f8f9fa" : undefined,
      "&:hover": {
        backgroundColor: !state.isSelected ? "#f8f9fa" : undefined,
      },
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: "var(--bs-gray-500)",
    }),
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      "color": "var(--bs-gray-500)",
      "&:hover": {
        color: "var(--bs-secondary)",
      },
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      "color": "var(--bs-gray-500)",
      "&:hover": {
        color: "var(--bs-secondary)",
      },
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "var(--bs-border-color)",
    }),
  };
  const theme: ThemeConfig = (theme) => ({
    ...theme,
    borderRadius: 6,
    colors: {
      ...theme.colors,
      primary: "var(--bs-primary)",
      primary75: "rgba(11, 151, 151, 0.75)",
      primary50: "rgba(11, 151, 151, 0.5)",
      primary25: "rgba(11, 151, 151, 0.25)",
      danger: "var(--bs-danger)",
      dangerLight: "rgba(196, 106, 106, 0.25)",
      neutral10: "#f8f9fa",
    },
  });
  return { styles, theme };
}

declare type BaseProps<T, IsMulti extends boolean = false> = CreatableProps<Option<T>, IsMulti, GroupBase<Option<T>>>;

export type SelectProps<T, IsMulti extends boolean = false> = { onChangeExtra?: () => void } & Omit<
  BaseProps<T, IsMulti>,
  "value" | "onChange" | "onBlur"
> &
  FieldInputProps<T>;

function Select<T = number, IsMulti extends boolean = false>({
  options,
  value,
  isMulti,
  onChange, // eslint-disable-line @typescript-eslint/no-unused-vars
  onChangeExtra,
  onCreateOption,
  ...props
}: SelectProps<T, IsMulti>) {
  const { setFieldValue } = useFormikContext();
  const { styles, theme } = getSelectStyles<Option<T>, IsMulti, GroupBase<Option<T>>>();

  const flattenedOptions = options?.flatMap((o) => {
    const isNotGrouped = "value" in o;
    if (isNotGrouped) {
      return o;
    } else {
      return o.options;
    }
  });

  let selectedOptions: PropsValue<Option<T>> = [];
  if (isMulti) {
    selectedOptions = String(value)
      ?.split(",")
      .map((v) => flattenedOptions?.find((option) => option.value === v))
      .filter((v) => v) as MultiValue<Option<T>>;
  } else {
    selectedOptions = flattenedOptions?.find((option) => option.value === value) as SingleValue<Option<T>>;
  }

  const onChangeSetFieldValue = (newValue: OnChangeValue<Option<T>, IsMulti>) => {
    if (isMulti) {
      const multiValue = newValue as MultiValue<Option<T>>;
      setFieldValue(props.name, multiValue.map((option) => option.value).join() || undefined);
    } else {
      const singleValue = newValue as SingleValue<Option<T>>;
      setFieldValue(props.name, singleValue?.value ?? undefined);
    }
    if (onChangeExtra) {
      onChangeExtra();
    }
  };

  if (onCreateOption) {
    return (
      <CreatableReactSelect
        options={options}
        value={selectedOptions ?? null}
        onChange={onChangeSetFieldValue}
        onCreateOption={onCreateOption}
        styles={styles}
        theme={theme}
        isMulti={isMulti}
        menuPosition="fixed"
        {...props}
      />
    );
  } else {
    return (
      <ReactSelect
        options={options}
        value={selectedOptions ?? null}
        onChange={onChangeSetFieldValue}
        styles={styles}
        theme={theme}
        isMulti={isMulti}
        menuPosition="fixed"
        {...props}
      />
    );
  }
}

export default Select;
