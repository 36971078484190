import classNames from "classnames";
import Badge from "react-bootstrap/Badge";

import "./StatusCodeBadge.css";
import Tooltip from "./Tooltip";

type StatusCodeBadgeProps = {
  code: number;
  text?: React.ReactNode;
  className?: string;
};

function StatusCodeBadge({ code, text, className }: StatusCodeBadgeProps) {
  return (
    <Tooltip tooltip={text}>
      <Badge
        bg="danger"
        className={classNames(
          "StatusCodeBadge",
          "cursor-default",
          {
            "outline-secondary": code < 200 || (code >= 300 && code < 400),
            "outline-primary": code >= 200 && code < 300,
            "outline-danger": code >= 400 && code < 500,
          },
          className,
        )}
      >
        {code}
      </Badge>
    </Tooltip>
  );
}

export default StatusCodeBadge;
