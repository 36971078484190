import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";

interface DebouncedFormControlProps extends FormControlProps {
  delay?: number;
}

function DebouncedFormControl({
  delay = 300,
  value: initialValue = "",
  onChange,
  ...props
}: DebouncedFormControlProps) {
  const [value, setValue] = useState<string>(initialValue.toString());

  useEffect(() => {
    setValue(initialValue.toString());
  }, [initialValue]);

  const debouncedOnChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    }, delay),
    [delay, onChange],
  );

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const syntheticEvent = {
      ...event,
      persist: () => {},
      target: {
        ...event.target,
        value: event.target.value,
        name: props.name,
      },
      currentTarget: { ...event.currentTarget },
    } as React.ChangeEvent<HTMLInputElement>;

    setValue(event.target.value);
    debouncedOnChange(syntheticEvent);
  };

  return <Form.Control value={value} onChange={handleChange} {...props} />;
}

export default DebouncedFormControl;
