import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { Interceptors } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AdminService } from './sdk.gen';
import { AlertsService } from './sdk.gen';
import { AppsService } from './sdk.gen';
import { ConsumersService } from './sdk.gen';
import { DefaultService } from './sdk.gen';
import { EndpointsService } from './sdk.gen';
import { ErrorsService } from './sdk.gen';
import { LemonSqueezyService } from './sdk.gen';
import { PerformanceService } from './sdk.gen';
import { PlansService } from './sdk.gen';
import { RequestLogService } from './sdk.gen';
import { TeamsService } from './sdk.gen';
import { TrafficService } from './sdk.gen';
import { UptimeService } from './sdk.gen';
import { UsersService } from './sdk.gen';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class BackendClient {

	public readonly admin: AdminService;
	public readonly alerts: AlertsService;
	public readonly apps: AppsService;
	public readonly consumers: ConsumersService;
	public readonly default: DefaultService;
	public readonly endpoints: EndpointsService;
	public readonly errors: ErrorsService;
	public readonly lemonSqueezy: LemonSqueezyService;
	public readonly performance: PerformanceService;
	public readonly plans: PlansService;
	public readonly requestLog: RequestLogService;
	public readonly teams: TeamsService;
	public readonly traffic: TrafficService;
	public readonly uptime: UptimeService;
	public readonly users: UsersService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? '',
			VERSION: config?.VERSION ?? '1.0.0',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
			interceptors: {
				request: config?.interceptors?.request ?? new Interceptors(),
				response: config?.interceptors?.response ?? new Interceptors(),
      },
		});

		this.admin = new AdminService(this.request);
		this.alerts = new AlertsService(this.request);
		this.apps = new AppsService(this.request);
		this.consumers = new ConsumersService(this.request);
		this.default = new DefaultService(this.request);
		this.endpoints = new EndpointsService(this.request);
		this.errors = new ErrorsService(this.request);
		this.lemonSqueezy = new LemonSqueezyService(this.request);
		this.performance = new PerformanceService(this.request);
		this.plans = new PlansService(this.request);
		this.requestLog = new RequestLogService(this.request);
		this.teams = new TeamsService(this.request);
		this.traffic = new TrafficService(this.request);
		this.uptime = new UptimeService(this.request);
		this.users = new UsersService(this.request);
	}
}
