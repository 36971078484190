import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import ModalBody from "react-bootstrap/ModalBody";

import "./CollapsibleModalBody.css";

type CollapsibleModalBodyProps = {
  title?: React.ReactNode;
  collapsible?: boolean;
  initialCollapsed?: boolean;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
};

function CollapsibleModalBody({
  title,
  collapsible = true,
  initialCollapsed = false,
  className = "",
  children,
}: CollapsibleModalBodyProps) {
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  return (
    <div className={classNames("CollapsibleModalBody", className)}>
      <a
        role="button"
        className={classNames("border-top", "very-small", "text-muted", "px-4", "py-1", "d-flex", {
          "border-bottom": !collapsed,
          "border-bottom-hidden": collapsed,
          "cursor-pointer": collapsible,
        })}
        style={{
          backgroundColor: "#faf9fb",
          transition: "border-bottom 0.3s ease",
        }}
        onClick={collapsible ? () => setCollapsed((collapsed) => !collapsed) : undefined}
      >
        <div>{title}</div>
        {collapsible && (
          <div className="ms-auto">
            <span
              className={classNames("fst-italic", "me-2", {
                "text-very-muted": collapsed,
                "text-transparent": !collapsed,
              })}
              style={{ transition: "color 0.3s ease" }}
            >
              Click to expand
            </span>
            <FontAwesomeIcon
              icon={faChevronDown}
              rotation={collapsed ? 90 : undefined}
              className="text-very-muted small"
              style={{ transition: "transform 0.3s ease" }}
            />
          </div>
        )}
      </a>
      <Collapse in={!collapsed}>
        <div>
          <ModalBody>{children}</ModalBody>
        </div>
      </Collapse>
    </div>
  );
}

export default CollapsibleModalBody;
