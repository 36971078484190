import { useQuery } from "@tanstack/react-query";
import {
  ChartData,
  Chart as ChartJS,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tick,
  TimeSeriesScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import AnnotationPlugin from "chartjs-plugin-annotation";
import { rgba } from "polished";
import { memo } from "react";
import { Line } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import ChartContainer from "../components/ChartContainer";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import { getChartOptions } from "../utils/charts";
import { getColor } from "../utils/colors";
import { formatResponseTime } from "../utils/numbers";

ChartJS.register(Filler, LinearScale, TimeSeriesScale, LineController, LineElement, PointElement, Title, Tooltip);
ChartJS.register(AnnotationPlugin);

type ResponseTimeLineChartProps = {
  endpoint?: Endpoint;
  displayTitle?: boolean;
  displayTargetLine?: boolean;
  style?: React.CSSProperties;
};

function ResponseTimeLineChart({
  endpoint,
  displayTitle = true,
  displayTargetLine = true,
  style,
}: ResponseTimeLineChartProps) {
  const { backendClient } = useGlobal();
  const { app, period, setPeriod, resetPeriod, env, consumerId, consumerGroupId, endpointGroupId } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointGroupId,
    method: endpoint?.method,
    path: endpoint?.path,
    period,
  };
  const query = useQuery({
    queryKey: ["responseTimeChart", queryParams],
    queryFn: () => backendClient!.performance.getResponseTimeChart(queryParams),
    enabled: !!backendClient && !!app,
  });

  let chart;
  if (app && query.isSuccess && query.data.length > 0) {
    const primaryColor = getColor("primary");
    const dangerColor = getColor("danger");
    const colors = new Map([
      ["p50", rgba(primaryColor, 1.0)],
      ["p75", rgba(primaryColor, 0.6)],
      ["p95", rgba(primaryColor, 0.35)],
    ]);
    const targetResponseTime = endpoint?.target_response_time_ms || app.target_response_time_ms;
    if (!query.data[0]?.response_times.some((responseTime) => responseTime > targetResponseTime)) {
      displayTargetLine = false;
    }
    const chartOptions = merge(
      getChartOptions({
        labels: query.data[0].time_windows,
        title: displayTitle ? "p50, p75 and p95 response times" : undefined,
        tooltipUnit: "ms",
        setPeriod,
        resetPeriod,
      }),
      {
        scales: {
          y: {
            ticks: {
              callback: function (value: string | number, index: number, ticks: Tick[]) {
                return formatResponseTime(Number(value), ticks.at(-1)?.value);
              },
            },
          },
        },
        tooltips: { intersect: false },
        interaction: { mode: "index" as const, intersect: false },
        plugins: {
          tooltip: {
            position: "nearest" as const,
          },
          annotation: {
            annotations: {
              targetLine: {
                type: "line" as const,
                display: displayTargetLine,
                yMin: targetResponseTime,
                yMax: targetResponseTime,
                borderColor: dangerColor,
                borderWidth: 0.75,
                label: {
                  content: "Threshold",
                  display: true,
                  font: {
                    family: "DM Sans",
                    weight: "normal" as const,
                    size: 10,
                  },
                  color: dangerColor,
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  position: "start" as const,
                  padding: {
                    top: 1,
                    bottom: 0,
                    left: 3,
                    right: 3,
                  },
                  borderRadius: 3,
                  yAdjust: -10,
                },
              },
            },
          },
        },
        hover: {
          intersect: false,
        },
      },
    );
    const chartData: ChartData<"line"> = {
      labels: query.data[0].time_windows,
      datasets: query.data.map((dataset) => ({
        label: dataset.percentile,
        data: dataset.response_times,
        borderColor: colors.get(dataset.percentile),
        backgroundColor: colors.get(dataset.percentile),
        pointStyle: false,
        borderWidth: 0,
        fill: true,
      })),
    };
    chart = <Line data={chartData} options={chartOptions} />;
  }

  return <ChartContainer style={style}>{chart}</ChartContainer>;
}

export default memo(ResponseTimeLineChart);
