import { faClock } from "@fortawesome/free-regular-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";

import { formatPeriod, isCustomPeriod, periodLabels } from "../utils/period";
import CustomDropdown from "./Dropdown";

type PeriodDropdownItemsProps = {
  period: string;
  setPeriod?: (period: string) => void;
  periods?: string[];
};

export function PeriodDropdownItems({ period, setPeriod, periods }: PeriodDropdownItemsProps) {
  return (
    <>
      {[...periodLabels]
        .filter(([key]) => !periods || periods.includes(key) || period === key)
        .map(([key, value]) => (
          <Dropdown.Item
            key={key}
            eventKey={key}
            active={period === key}
            onClick={setPeriod ? () => setPeriod(key) : undefined}
          >
            {value}
          </Dropdown.Item>
        ))}
    </>
  );
}

type PeriodDropdownProps = {
  period: string;
  setPeriod: (period: string) => void;
  periods?: string[];
  setShowCustomPeriodModal?: (show: boolean) => void;
} & React.ComponentPropsWithoutRef<typeof CustomDropdown>;

function PeriodDropdown({ period, setPeriod, periods, setShowCustomPeriodModal, ...props }: PeriodDropdownProps) {
  return (
    <>
      <CustomDropdown
        title={period ? formatPeriod(period, { expandCustom: false }) : "-"}
        icon={faClock}
        onSelect={(period: string | null) => period !== null && setPeriod(period)}
        {...props}
      >
        <Dropdown.Menu>
          <Dropdown.Header>Select period</Dropdown.Header>
          <PeriodDropdownItems period={period} periods={periods} />
          {setShowCustomPeriodModal && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item active={isCustomPeriod(period)} onClick={() => setShowCustomPeriodModal(true)}>
                Custom
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </CustomDropdown>
    </>
  );
}

export default PeriodDropdown;
