import { DateTime } from "luxon";

export function formatRelativeDateTime(datetime?: DateTime | string, short: boolean = false, never: boolean = true) {
  if (!datetime) {
    return never ? "Never" : null;
  }
  if (!(datetime instanceof DateTime)) {
    datetime = DateTime.fromISO(datetime);
  }
  const now = DateTime.now();
  if (Math.abs(now.diff(datetime).as("minutes")) < 1) {
    return short ? "< 1 min ago" : "< 1 minute ago";
  }
  let relative = datetime.toRelative({ style: short ? "short" : "long" });
  if (relative && short) {
    relative = relative.replace(/(?<!^1 )\bhr\b/g, "hrs");
    relative = relative.replace(/(?<!^1 )\bmin\b/g, "mins");
  }
  return relative;
}

export function formatRelativeDate(date: DateTime | string) {
  if (!(date instanceof DateTime)) {
    date = DateTime.fromISO(date);
  }
  const now = DateTime.now();
  if (date.hasSame(now, "day")) {
    return "Today";
  }
  if (date.hasSame(now.minus({ days: 1 }), "day")) {
    return "Yesterday";
  }
  return date.toRelativeCalendar({ unit: "days" });
}

export function formatDateTimeDiff(
  datetime1?: DateTime | string,
  datetime2?: DateTime | string,
  short: boolean = false,
) {
  if (!datetime1 || !datetime2) {
    return null;
  }
  if (!(datetime1 instanceof DateTime)) {
    datetime1 = DateTime.fromISO(datetime1);
  }
  if (!(datetime2 instanceof DateTime)) {
    datetime2 = DateTime.fromISO(datetime2);
  }
  let duration = datetime2.diff(datetime1, ["days", "hours", "minutes"]);
  if (duration.as("minutes") < 1) {
    return short ? "< 1 min" : "< 1 minute";
  }
  duration = duration.rescale().set({ seconds: 0, milliseconds: 0 }).rescale();
  return duration.toHuman({ unitDisplay: short ? "short" : "long", notation: "compact" });
}
