import React from "react";
import OverlayTrigger, { OverlayTriggerProps } from "react-bootstrap/OverlayTrigger";
import BootstrapTooltip from "react-bootstrap/Tooltip";

type TooltipProps = {
  condition?: boolean;
  tooltip?: React.ReactNode;
  children: React.ReactElement;
} & Omit<OverlayTriggerProps, "overlay">;

const Tooltip = ({ condition, tooltip, children, ...overlayTriggerProps }: TooltipProps) => {
  const wrappedChildren = children.props.disabled ? <span>{children}</span> : children;
  return (condition === undefined || condition) && tooltip ? (
    <OverlayTrigger
      overlay={(props) => {
        props.style = { ...props.style, position: "fixed" };
        return <BootstrapTooltip {...props}>{tooltip}</BootstrapTooltip>;
      }}
      {...overlayTriggerProps}
    >
      {wrappedChildren}
    </OverlayTrigger>
  ) : (
    children
  );
};

export default Tooltip;
