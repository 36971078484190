import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import capitalize from "lodash/capitalize";

import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { formatPeriod, isCustomPeriod } from "../utils/period";
import FilterBadge from "./FilterBadge";
import "./FilterBadges.css";

type FilterBadgesProps = {
  level?: number;
  clearLevel?: () => void;
  showAll?: boolean;
  className?: string;
};

function FilterBadges({ level, clearLevel, showAll, className }: FilterBadgesProps) {
  const { backendClient } = useGlobal();
  const {
    app,
    period,
    resetPeriod,
    env,
    setEnv,
    consumerId,
    setConsumerId,
    consumerGroupId,
    setConsumerGroupId,
    endpointId,
    setEndpointId,
    endpointGroupId,
    setEndpointGroupId,
    url,
    setUrl,
    method,
    setMethod,
    statusCode,
    setStatusCode,
    minRequestSize,
    maxRequestSize,
    setRequestSizeRange,
    minResponseSize,
    maxResponseSize,
    setResponseSizeRange,
    minResponseTime,
    maxResponseTime,
    setResponseTimeRange,
  } = useFilters();

  const customPeriod = period && isCustomPeriod(period);
  const alwaysShowBadges =
    showAll ||
    consumerId ||
    consumerGroupId ||
    endpointId ||
    endpointGroupId ||
    url ||
    method ||
    statusCode ||
    minRequestSize !== undefined ||
    maxRequestSize !== undefined ||
    minResponseSize !== undefined ||
    maxResponseSize !== undefined ||
    minResponseTime !== undefined ||
    maxResponseTime !== undefined ||
    customPeriod;
  const mdShowBadges = alwaysShowBadges || period || env;

  const consumerQueryParams = { appId: app?.id || 0, consumerId: consumerId || 0 };
  const { data: consumer } = useQuery({
    queryKey: ["consumer", consumerQueryParams],
    queryFn: () => backendClient!.consumers.getConsumer(consumerQueryParams),
    enabled: !!backendClient && !!app && !!consumerId,
  });
  const consumerGroupQueryParams = { appId: app?.id || 0, consumerGroupId: consumerGroupId || 0 };
  const { data: consumerGroup } = useQuery({
    queryKey: ["consumerGroup", consumerGroupQueryParams],
    queryFn: () => backendClient!.consumers.getConsumerGroup(consumerGroupQueryParams),
    enabled: !!backendClient && !!app && !!consumerGroupId,
  });
  const endpointQueryParams = { appId: app?.id || 0, endpointId: endpointId || 0 };
  const { data: endpoint } = useQuery({
    queryKey: ["endpoint", endpointQueryParams],
    queryFn: () => backendClient!.endpoints.getEndpoint(endpointQueryParams),
    enabled: !!backendClient && !!app && !!endpointId,
  });
  const endpointGroupQueryParams = { appId: app?.id || 0, endpointGroupId: endpointGroupId || 0 };
  const { data: endpointGroup } = useQuery({
    queryKey: ["endpointGroup", endpointGroupQueryParams],
    queryFn: () => backendClient!.endpoints.getEndpointGroup(endpointGroupQueryParams),
    enabled: !!backendClient && !!app && !!endpointGroupId,
  });

  return (
    <div
      className={classNames(
        "FilterBadges",
        { "mb-4": mdShowBadges },
        "mb-md-0",
        { "d-md-none": !alwaysShowBadges },
        className,
      )}
    >
      {period && (
        <FilterBadge
          icon={faClock}
          label="Period"
          value={formatPeriod(period)}
          className={!customPeriod && !showAll ? "d-md-none" : undefined}
          removeFilter={customPeriod ? resetPeriod : undefined}
        />
      )}
      {env && (
        <FilterBadge
          icon="/icons/layer-group-regular.svg"
          label="Env"
          value={capitalize(env.slug)}
          removeFilter={() => setEnv(undefined)}
          className={!showAll ? "d-md-none" : undefined}
        />
      )}
      {consumerId && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Consumer"
          value={consumer?.name || "..."}
          removeFilter={() => setConsumerId(undefined)}
        />
      )}
      {consumerGroupId && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Consumer group"
          value={consumerGroup?.name || "..."}
          removeFilter={() => setConsumerGroupId(undefined)}
        />
      )}
      {endpointId && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Endpoint"
          value={endpoint ? endpoint.method + " " + endpoint.path : "..."}
          removeFilter={() => setEndpointId(undefined)}
        />
      )}
      {endpointGroupId && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Endpoint group"
          value={endpointGroup?.name || "..."}
          removeFilter={() => setEndpointGroupId(undefined)}
        />
      )}
      {url && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="URL"
          value={url}
          op="contains"
          removeFilter={() => setUrl(undefined)}
        />
      )}
      {method && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Method"
          value={method.split(",").join(", ")}
          removeFilter={() => setMethod(undefined)}
        />
      )}
      {statusCode && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Status code"
          value={statusCode.split(",").join(", ")}
          removeFilter={() => setStatusCode(undefined)}
        />
      )}
      {(minRequestSize !== undefined || maxRequestSize !== undefined) && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Request size"
          value={
            minRequestSize !== undefined && maxRequestSize === undefined
              ? `${minRequestSize} KB`
              : minRequestSize === undefined && maxRequestSize !== undefined
                ? `${maxRequestSize} KB`
                : `${minRequestSize} - ${maxRequestSize} KB`
          }
          op={
            minRequestSize !== undefined && maxRequestSize === undefined
              ? "≥"
              : minRequestSize === undefined && maxRequestSize !== undefined
                ? "≤"
                : "="
          }
          removeFilter={() => setRequestSizeRange(undefined, undefined)}
        />
      )}
      {(minResponseSize !== undefined || maxResponseSize !== undefined) && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Response size"
          value={
            minResponseSize !== undefined && maxResponseSize === undefined
              ? `${minResponseSize} KB`
              : minResponseSize === undefined && maxResponseSize !== undefined
                ? `${maxResponseSize} KB`
                : `${minResponseSize} - ${maxResponseSize} KB`
          }
          op={
            minResponseSize !== undefined && maxResponseSize === undefined
              ? "≥"
              : minResponseSize === undefined && maxResponseSize !== undefined
                ? "≤"
                : "="
          }
          removeFilter={() => setResponseSizeRange(undefined, undefined)}
        />
      )}
      {(minResponseTime !== undefined || maxResponseTime !== undefined) && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Response time"
          value={
            minResponseTime !== undefined && maxResponseTime === undefined
              ? `${minResponseTime} ms`
              : minResponseTime === undefined && maxResponseTime !== undefined
                ? `${maxResponseTime} ms`
                : `${minResponseTime} - ${maxResponseTime} ms`
          }
          op={
            minResponseTime !== undefined && maxResponseTime === undefined
              ? "≥"
              : minResponseTime === undefined && maxResponseTime !== undefined
                ? "≤"
                : "="
          }
          removeFilter={() => setResponseTimeRange(undefined, undefined)}
        />
      )}
      {level && (
        <FilterBadge
          icon="/icons/filter-regular.svg"
          label="Level"
          value={level.toString()}
          removeFilter={clearLevel}
        />
      )}
    </div>
  );
}

export default FilterBadges;
