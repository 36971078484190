import { IconDefinition, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "react-bootstrap/Badge";

import CustomIcon from "./CustomIcon";
import "./FilterBadge.css";

type FilterBadgeProps = {
  icon?: IconDefinition | string;
  label?: string;
  value?: string | null;
  noValue?: boolean;
  op?: string;
  removeFilter?: () => void;
  className?: string;
};

function FilterBadge({ icon, label, value, noValue = false, op = "=", removeFilter, className }: FilterBadgeProps) {
  if (!value && !noValue) {
    return <></>;
  }
  const iconClassName = "me-icon text-secondary";
  const iconElement =
    typeof icon === "string" ? (
      <CustomIcon src={icon} className={iconClassName} />
    ) : (
      <FontAwesomeIcon icon={icon as IconDefinition} className={iconClassName} />
    );
  return (
    <div className={`FilterBadge d-inline-block ${className || ""}`}>
      <Badge bg="light">
        <div>{iconElement}</div>
        <div>
          {label}
          {label && value && (
            <>
              {" "}
              {op} <b>{value}</b>
            </>
          )}
          {!label && value}
        </div>
        {removeFilter && (
          <a role="button" onClick={() => removeFilter()} className="text-very-muted">
            <FontAwesomeIcon icon={faXmark} />
          </a>
        )}
      </Badge>
    </div>
  );
}

export default FilterBadge;
