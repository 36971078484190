import { useQuery } from "@tanstack/react-query";
import {
  ActiveElement,
  BarController,
  BarElement,
  Chart,
  ChartEvent,
  Chart as ChartJS,
  ChartMeta,
  LinearScale,
  Plugin,
  TimeScaleOptions,
  TimeSeriesScale,
} from "chart.js";
import "chartjs-adapter-luxon";
import AnnotationPlugin, { PartialEventContext } from "chartjs-plugin-annotation";
import ZoomPlugin from "chartjs-plugin-zoom";
import { DateTime } from "luxon";
import { memo } from "react";
import { Bar } from "react-chartjs-2";
import { merge } from "ts-deepmerge";

import ChartContainer from "../components/ChartContainer";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { getChartOptions, getTimeScale } from "../utils/charts";
import { getColor } from "../utils/colors";
import { formatDate, isToday, isYesterday } from "../utils/period";

ChartJS.register(LinearScale, TimeSeriesScale, BarController, BarElement);
ChartJS.register(AnnotationPlugin, ZoomPlugin);

declare module "chart.js" {
  interface Chart {
    hoverValue?: number;
    hoverLabel?: string;
  }
}

type RequestLogTimelineBarChartProps = {
  timestamp?: DateTime;
  setTimestamp: (timestamp?: DateTime) => void;
};

function RequestLogTimelineBarChart({ timestamp, setTimestamp }: RequestLogTimelineBarChartProps) {
  const { backendClient } = useGlobal();
  const {
    app,
    env,
    period,
    setPeriod,
    resetPeriod,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
    url,
    minRequestSize,
    maxRequestSize,
    minResponseSize,
    maxResponseSize,
    minResponseTime,
    maxResponseTime,
  } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    consumerId,
    consumerGroupId,
    endpointId,
    endpointGroupId,
    method,
    statusCode,
    url,
    minRequestSize,
    maxRequestSize,
    minResponseSize,
    maxResponseSize,
    minResponseTime,
    maxResponseTime,
    period,
  };
  const query = useQuery({
    queryKey: ["requestLogTimelineChart", queryParams],
    queryFn: () => backendClient!.requestLog.getRequestLogTimeline(queryParams),
    enabled: !!backendClient && !!app,
  });

  let chart;
  if (query.isSuccess) {
    const secondaryColor = getColor("secondary");
    const dangerColor = getColor("danger");
    const timeScale = getTimeScale(query.data.time_windows);
    const chartOptions = merge(
      getChartOptions({
        labels: query.data.time_windows,
        setPeriod: (period: string) => {
          setPeriod(period);
          setTimestamp(undefined);
        },
        resetPeriod,
      }),
      {
        maintainAspectRatio: false,
        scales: {
          x: {
            offset: false,
          },
          y: {
            display: false,
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          crosshair: {
            line: {
              color: "red",
              width: 1,
            },
          },
          annotation: {
            annotations: {
              timestampLine: {
                type: "line" as const,
                display: timestamp !== undefined,
                xMin: timestamp?.toMillis() || 0,
                xMax: timestamp?.toMillis() || 0,
                borderColor: dangerColor,
                borderWidth: 2.0,
                borderDash: [2, 2],
              },
              whiteOutBox: {
                type: "box" as const,
                adjustScaleRange: false,
                display: timestamp !== undefined,
                xMin: timestamp?.toMillis() || 0,
                xMax: (context: PartialEventContext) => context.chart.scales.x.max + timeScale.step,
                yMin: 0,
                yMax: (context: PartialEventContext) => context.chart.scales.y.max,
                backgroundColor: "#ffffff88",
                borderWidth: 0,
              },
            },
          },
          zoom: {
            zoom: {
              drag: {
                modifierKey: "meta" as const,
              },
              onZoomStart: (ctx: { chart: Chart }) => {
                const xAxisOptions = ctx.chart.scales.x.options as TimeScaleOptions;
                xAxisOptions.ticks.display = true;
                ctx.chart.hoverValue = undefined;
                ctx.chart.hoverLabel = undefined;
                ctx.chart.draw();
                return true;
              },
            },
          },
        },
        onClick: function (event: ChartEvent, elements: ActiveElement[], chart: Chart) {
          if (event.x) {
            const time = chart.scales.x.getValueForPixel(event.x);
            if (time) {
              const dt = DateTime.fromMillis(time);
              setTimestamp(dt);
            }
          }
        },
      },
    );
    const chartData = {
      labels: query.data.time_windows,
      datasets: [
        {
          data: query.data.item_counts,
          backgroundColor: "#e2e3e5",
          hoverBackgroundColor: "#e2e3e5",
          borderWidth: { left: 1, right: 1, top: 0, bottom: 0 },
          borderColor: "#ffffff00",
          borderSkipped: false,
          barPercentage: 1.0,
          categoryPercentage: 1.0,
        },
      ],
    };
    const chartPlugins: Plugin[] = [
      {
        id: "verticalHoverLine",
        beforeEvent: function (chart, args) {
          if (chart.isZoomingOrPanning()) {
            return;
          }
          const xAxisOptions = chart.scales.x.options as TimeScaleOptions;
          const y0 = chart.scales.y.getPixelForValue(0);
          if (args.event.type === "mousemove" && args.event.x && args.event.y && args.event.y <= y0) {
            xAxisOptions.ticks.display = false;
            chart.hoverValue = chart.scales.x.getValueForPixel(args.event.x);
            if (chart.hoverValue) {
              const dt = DateTime.fromMillis(chart.hoverValue);
              const minDt = DateTime.fromMillis(chart.scales.x.min);
              const includeDay = !isToday(minDt) && !isYesterday(minDt);
              chart.hoverLabel = (includeDay ? formatDate(dt) + ", " : "") + dt.toFormat("HH:mm:ss");
            }
            chart.draw();
          } else if (
            (args.event.type === "mousemove" && args.event.y && args.event.y > y0) ||
            args.event.type === "mouseout"
          ) {
            xAxisOptions.ticks.display = true;
            chart.hoverValue = undefined;
            chart.hoverLabel = undefined;
            chart.draw();
          }
        },
        afterDraw: function (chart) {
          if (chart.hoverValue) {
            const x = chart.scales.x.getPixelForValue(chart.hoverValue);
            const y = chart.scales.y.getPixelForValue(0);
            const ctx = chart.ctx;

            // Draw the vertical line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, y);
            ctx.strokeStyle = secondaryColor;
            ctx.lineWidth = 1;
            ctx.stroke();
            ctx.restore();

            // Draw the label
            if (chart.hoverLabel) {
              ctx.font = "12px DM Sans";
              ctx.fillStyle = "#adb5bd"; // Same color as ticks
              ctx.textAlign = "center";
              ctx.textBaseline = "top";

              const labelWidth = ctx.measureText(chart.hoverLabel).width;
              const labelY = y + 12; // Same position as ticks
              let labelX = x;
              if (labelX - labelWidth / 2 < 0) {
                labelX = labelWidth / 2;
              } else if (labelX + labelWidth / 2 > chart.width) {
                labelX = chart.width - labelWidth / 2;
              }

              ctx.save();
              ctx.fillText(chart.hoverLabel, labelX, labelY);
              ctx.restore();
            }
          }
        },
      },
      {
        id: "shiftBars",
        afterUpdate: function (chart) {
          const barMetas = chart.getSortedVisibleDatasetMetas().filter((meta) => meta.type === "bar") as ChartMeta<
            "bar",
            BarElement
          >[];
          barMetas.forEach((meta) => {
            meta.data.forEach((bar) => {
              const barWidth = bar.getProps(["width"], true).width;
              bar.x += barWidth / 2;
            });
          });
        },
      },
    ];
    chart = <Bar data={chartData} options={chartOptions} plugins={chartPlugins} />;
  }

  return <ChartContainer height={80}>{chart}</ChartContainer>;
}

export default memo(RequestLogTimelineBarChart);
