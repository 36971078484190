import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import { useGlobal } from "../contexts/GlobalContext";

type UpgradePlanAlertProps = {
  variant?: string;
  upgradeButtonOnClick?: () => void;
  extraButtons?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode;
  dismissible?: boolean;
  dismissKey?: string;
  className?: string;
};

function UpgradePlanAlert({
  variant = "primary",
  upgradeButtonOnClick,
  extraButtons,
  children,
  dismissible,
  dismissKey,
  className,
}: UpgradePlanAlertProps) {
  const { teamPlan } = useGlobal();
  const navigate = useNavigate();
  const [show, setShow] = useState(dismissible && dismissKey ? sessionStorage.getItem(dismissKey) !== "1" : true);

  if (!upgradeButtonOnClick) {
    upgradeButtonOnClick = () => navigate("/settings?upgradePlan");
  }

  const onDismiss = () => {
    if (dismissKey) {
      sessionStorage.setItem(dismissKey, "1");
    }
    setShow(false);
  };

  return (
    <Alert variant={variant} show={show} dismissible={dismissible} onClose={onDismiss} className={className}>
      {children}
      <div className="mt-4 d-flex gap-2">
        {teamPlan?.upgradable && (
          <Button variant={variant} onClick={upgradeButtonOnClick}>
            Upgrade plan
          </Button>
        )}
        {teamPlan && !teamPlan.upgradable && (
          <Button variant={variant} onClick={() => window.open("mailto:support@apitally.io")}>
            Contact us
          </Button>
        )}
        {extraButtons}
      </div>
    </Alert>
  );
}

export default UpgradePlanAlert;
