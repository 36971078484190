import { ActiveElement, Chart, ChartConfiguration, ChartEvent, ChartOptions, ChartType, TooltipItem } from "chart.js";
import ZoomPlugin from "chartjs-plugin-zoom";
import { DateTime } from "luxon";
import { formatPeriodStep, getCustomPeriodString } from "./period";

Chart.register(ZoomPlugin);

type TimeScale = {
  step: number;
  range: number;
  min: number;
  max: number;
};

export function getTimeScale(labels: string[]): TimeScale {
  if (!labels || labels.length < 2 || typeof labels[0] !== "string" || typeof labels[1] !== "string") {
    return { step: 0, range: 0, min: 0, max: 0 };
  }
  const first = DateTime.fromISO(labels[0]);
  const second = DateTime.fromISO(labels[1]);
  const last = DateTime.fromISO(labels.at(-1)!);
  return {
    step: second.diff(first).as("milliseconds"),
    range: last.diff(first).as("milliseconds"),
    min: first.toMillis(),
    max: last.toMillis(),
  };
}

function getTimeScaleUnit(timeScale: TimeScale) {
  const stepHours = timeScale.step / 1000 / 60 / 60;
  const rangeDays = timeScale.range / 1000 / 60 / 60 / 24;
  if (stepHours <= 6) {
    if (rangeDays < 28) {
      return "hour" as const;
    } else {
      return "day" as const;
    }
  } else if (stepHours < 24 * 7) {
    return "day" as const;
  } else if (stepHours <= 24 * 28) {
    return "week" as const;
  } else if (stepHours < 24 * 30 * 12) {
    return "month" as const;
  } else {
    return "year" as const;
  }
}

interface ResetPeriodPluginOptions {
  resetPeriod?: () => void;
}

const resetPeriodPlugin = {
  id: "resetPeriod",
  afterEvent: (chart: Chart, args: { event: ChartEvent }, options: ResetPeriodPluginOptions) => {
    if (options.resetPeriod && args.event.native && args.event.type === "dblclick") {
      const res = chart.getElementsAtEventForMode(args.event.native, "x", { intersect: true }, true);
      if (res.length === 0) {
        options.resetPeriod();
      }
    }
  },
  defaults: {
    resetPeriod: null as (() => void) | null,
  },
};

Chart.register(resetPeriodPlugin);

declare module "chart.js" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    resetPeriod: ResetPeriodPluginOptions;
  }
}

type GetChartOptionsOptions = {
  labels: string[];
  title?: string;
  tooltipUnit?: string;
  tooltipTotalFooter?: boolean;
  setPeriod?: (period: string) => void;
  resetPeriod?: () => void;
};

export function getChartOptions({
  labels,
  title,
  tooltipUnit,
  tooltipTotalFooter = false,
  setPeriod,
  resetPeriod,
}: GetChartOptionsOptions) {
  const timeScale = getTimeScale(labels);
  const stepMinutes = timeScale.step / 1000 / 60;
  const stepHours = stepMinutes / 60;
  const rangeHours = timeScale.range / 1000 / 60 / 60;
  const isMonthlyStep = stepHours >= 24 * 28;
  const isHighFreq = labels.length >= 60;
  const lastLabel = labels.at(-1);
  const periodEndWithin31Days = lastLabel && DateTime.fromISO(lastLabel).diffNow().as("days") > -31;
  const timeScaleUnit = getTimeScaleUnit(timeScale);
  const useAmericanDateFormat = navigator.language === "en-US";
  const dayMonthFormat = useAmericanDateFormat ? "MMM d" : "d MMM";
  const allowPeriodSelection = setPeriod && ((periodEndWithin31Days && stepMinutes >= 1) || stepMinutes >= 30);

  const chartOptions: ChartOptions<"bar" | "line"> = {
    responsive: true,
    maintainAspectRatio: false,
    font: {
      family: "DM Sans",
    },
    scales: {
      x: {
        type: "timeseries" as const,
        time: {
          unit: timeScaleUnit,
          displayFormats: {
            day: dayMonthFormat,
            hour: "HH:mm",
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: "DM Sans",
            size: 12,
          },
          color: "#adb5bd",
          maxRotation: 0,
          callback:
            isHighFreq && (timeScaleUnit === "hour" || timeScaleUnit === "day")
              ? function (tickValue: string | number) {
                  const time = DateTime.fromMillis(tickValue as number);
                  let show;
                  if (timeScaleUnit === "hour") {
                    if (rangeHours >= 7 * 24) {
                      show = time.hour % 3 === 0 && time.minute === 0;
                    } else if (rangeHours >= 2 * 24) {
                      show = time.hour % 2 === 0 && time.minute === 0;
                    } else if (rangeHours >= 24) {
                      show = time.minute === 0;
                    } else if (rangeHours > 1) {
                      show = time.minute % 10 === 0;
                    } else {
                      show = time.minute % 5 === 0;
                    }
                    return show ? time.toFormat("HH:mm") : null;
                  } else if (timeScaleUnit === "day") {
                    show = time.hour === 0 && time.minute === 0;
                    return show ? time.toFormat(dayMonthFormat) : null;
                  }
                }
              : Chart.defaults.scales.time.ticks.callback,
        },
      },
      y: {
        grid: {
          display: true,
          color: "#faf9fb",
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: "DM Sans",
          },
          color: "#adb5bd",
        },
        display: true,
        beginAtZero: true,
      },
    },
    animation: {
      duration: 0,
    },
    interaction: {
      mode: "index",
    },
    plugins: {
      title: {
        display: !!title,
        text: title,
        font: {
          family: "DM Sans",
          size: 14,
          weight: "bold" as const,
        },
      },
      tooltip: {
        mode: "index" as const,
        callbacks: {
          title: function (context: TooltipItem<"bar" | "line">[]) {
            const start = DateTime.fromMillis(context[0].parsed.x);
            const end = isMonthlyStep ? start.plus({ months: 1 }) : start.plus(timeScale.step);
            return formatPeriodStep(start, end);
          },
          label: function (context: TooltipItem<"bar" | "line">) {
            const value = context.parsed.y.toLocaleString();
            const unit = tooltipUnit ? " " + tooltipUnit : "";
            return ` ${context.dataset.label}: ${value}${unit}`;
          },
          footer: tooltipTotalFooter
            ? (context: TooltipItem<"bar">[]) => {
                const total = context.reduce((acc, item) => acc + item.parsed.y, 0);
                return `Total: ${total.toLocaleString()}`;
              }
            : undefined,
        },
        titleFont: {
          family: "DM Sans",
          size: 13,
          weight: "bold" as const,
        },
        bodyFont: {
          family: "DM Sans",
          size: 13,
          weight: "normal" as const,
        },
        footerFont: {
          family: "DM Sans",
          size: 13,
          weight: "normal" as const,
        },
        footerColor: "#ccc",
      },
      legend: {
        display: false,
      },
      zoom: {
        zoom: {
          drag: {
            enabled: !!setPeriod,
            backgroundColor: "rgba(246, 245, 248, 0.25)",
            borderColor: "#d2d2d2",
            borderWidth: 0.75,
            threshold: 10,
            drawTime: "afterDatasetsDraw" as const,
          },
          mode: "x" as const,
          onZoomStart: (ctx: { chart: Chart }) => {
            ctx.chart.tooltip?.setActiveElements([], { x: 0, y: 0 }); // Hide tooltip
            return true;
          },
          onZoomComplete: (ctx: { chart: Chart }) => {
            if (setPeriod && ctx.chart.data.labels && ctx.chart.isZoomedOrPanned()) {
              const zoomStart = DateTime.fromMillis(ctx.chart.scales.x.min);
              const zoomEnd = DateTime.fromMillis(ctx.chart.scales.x.max);
              ctx.chart.resetZoom(); // Don't actually zoom, but let setPeriod handle the selection
              const labels = ctx.chart.data.labels.map((label) => DateTime.fromISO(label as string));
              const startMax = labels.at(-1);
              const endMax = startMax?.plus({ milliseconds: timeScale.step });
              const start = labels.find((label) => label >= zoomStart) || startMax;
              const end = labels.find((label) => label > zoomEnd) || endMax;
              if (start && end) {
                const diffMinutes = end?.diff(start).as("minutes");
                if (diffMinutes >= 30 || (periodEndWithin31Days && diffMinutes >= 1)) {
                  setTimeout(() => {
                    setPeriod(getCustomPeriodString(start, end));
                  });
                }
              }
            }
          },
        },
        limits: {
          x: { minRange: 60000 },
        },
      },
      resetPeriod: {
        resetPeriod,
      },
    },
    onHover: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
      const chartType = (chart.config as ChartConfiguration).type;
      if (event.native?.target && event.native.target instanceof HTMLElement && chartType === "bar") {
        event.native.target.style.cursor = elements.length > 0 && allowPeriodSelection ? "pointer" : "default";
      }
    },
    onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
      const chartType = (chart.config as ChartConfiguration).type;
      if (event.native && allowPeriodSelection && chartType === "bar") {
        const res = chart.getElementsAtEventForMode(event.native, "x", { intersect: true }, true);
        if (res.length > 0) {
          const start = DateTime.fromISO(labels[res[0].index]);
          const end = isMonthlyStep ? start.plus({ months: 1 }) : start.plus(timeScale.step);
          if ((start.diffNow().as("days") > -31 && stepMinutes >= 1) || stepMinutes >= 30) {
            const period = getCustomPeriodString(start, end);
            setPeriod(period);
          }
        }
      }
    },
    events: ["mousemove", "mouseout", "click", "dblclick", "touchstart", "touchmove"],
  };

  return chartOptions;
}
