import { Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { ApiError } from "../backend";
import CollapsibleModalBody from "../components/CollapsibleModalBody";
import { useGlobal } from "../contexts/GlobalContext";
import { validateEmail } from "../utils/email";

type ProfileFormModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDeleteAccountModal: React.Dispatch<React.SetStateAction<boolean>>;
};

interface ProfileFormValues {
  name: string;
  email: string;
}

function ProfileFormModal({ show, setShow, setShowDeleteAccountModal }: ProfileFormModalProps) {
  const { user } = useAuth0();
  const { backendClient } = useGlobal();
  const [initialFormValues, setInitialFormValues] = useState<ProfileFormValues>();
  const loginMethod = user?.sub?.split("|")[0];

  useEffect(() => {
    if (user) {
      setInitialFormValues({ name: user.name || "", email: user.email || "" });
    } else {
      setInitialFormValues(undefined);
    }
  }, [user, show]);

  const validate = async (values: ProfileFormValues) => {
    const errors: Partial<ProfileFormValues> = {};
    if (!values.name || values.name.trim() === "") {
      errors.name = "Required";
    }
    if (!validateEmail(values.email)) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  const updateProfile = async (values: ProfileFormValues) => {
    if (backendClient) {
      const promise = backendClient.users.updateProfile({
        requestBody: {
          name: values.name,
          email: values.email,
        },
      });
      toast.promise(promise, {
        pending: "Updating profile...",
        success: "Profile updated!",
        error: "Failed to update profile.",
      });
      await promise;
      if (user) {
        user.name = values.name;
        if (values.email !== user.email && loginMethod === "auth0") {
          user.email = values.email;
          user.email_verified = false;
        }
      }
    }
  };

  if (!initialFormValues) {
    return <></>;
  }

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      validate={validate}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          await updateProfile(values);
          setShow(false);
        } catch (e) {
          if (e instanceof ApiError && e.status === 409) {
            setFieldError("email", "A different user with this email address already exists.");
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors, touched, resetForm }) => (
        <Modal show={show} onHide={() => setShow(false)} onExited={() => resetForm()}>
          <Modal.Header closeButton>
            <Modal.Title>Update profile</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <BootstrapForm.Group controlId="formName">
                <BootstrapForm.Label>
                  Name <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <Field name="name">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      type="text"
                      placeholder="Your name"
                      maxLength={64}
                      isInvalid={!!errors.name && !!touched.name}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.name}</BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formEmail" className="mt-4">
                <BootstrapForm.Label>
                  Email address <span className="text-danger">*</span>
                </BootstrapForm.Label>
                <Field name="email">
                  {({ field }: FieldProps<string>) => (
                    <BootstrapForm.Control
                      type="text"
                      placeholder="Email address"
                      maxLength={256}
                      isInvalid={!!errors.email && !!touched.email}
                      disabled={loginMethod !== "auth0"}
                      {...field}
                    />
                  )}
                </Field>
                <BootstrapForm.Control.Feedback type="invalid">{errors.email}</BootstrapForm.Control.Feedback>
                <BootstrapForm.Text muted>
                  {loginMethod === "auth0" &&
                    "If you update your email address, you will be asked to verify your new email address and log in again."}
                  {loginMethod === "github" && "Your email address is linked to your GitHub profile."}
                  {loginMethod === "google-oauth2" && "Your email address is linked to your Google account."}
                </BootstrapForm.Text>
              </BootstrapForm.Group>
            </Modal.Body>
            <CollapsibleModalBody title="Delete account" initialCollapsed>
              <BootstrapForm.Group controlId="formDeleteAccount">
                <BootstrapForm.Text muted className="mt-0 mb-3">
                  Deleting your account will permanently remove your data from our servers. This action cannot be
                  undone.
                </BootstrapForm.Text>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setShow(false);
                    setShowDeleteAccountModal(true);
                  }}
                >
                  Delete your account
                </Button>
              </BootstrapForm.Group>
            </CollapsibleModalBody>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShow(false)}>
                Discard
              </Button>
              <Button type="submit" onClick={() => handleSubmit()} disabled={isSubmitting}>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default ProfileFormModal;
