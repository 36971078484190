import { useQuery } from "@tanstack/react-query";
import {
  ActiveElement,
  BarController,
  BarElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import "chartjs-adapter-luxon";
import { getReasonPhrase } from "http-status-codes";
import { rgba } from "polished";
import { memo } from "react";
import { Bar } from "react-chartjs-2";

import ChartContainer from "../components/ChartContainer";
import { useFilters } from "../contexts/FilterContext";
import { useGlobal } from "../contexts/GlobalContext";
import { Endpoint } from "../types/Endpoint";
import { getColor } from "../utils/colors";

ChartJS.register(BarController, BarElement, CategoryScale, Title, Tooltip);

type RequestsByConsumerBarChartProps = {
  endpoint: Endpoint;
  limit?: number;
};

function RequestsByConsumerBarChart({ endpoint, limit = 10 }: RequestsByConsumerBarChartProps) {
  const { backendClient } = useGlobal();
  const { app, period, env, setConsumerId, consumerGroupId } = useFilters();

  const queryParams = {
    appId: app?.id || 0,
    appEnv: env?.slug,
    method: endpoint.method,
    path: endpoint.path,
    consumerGroupId,
    period,
    limit,
  };
  const query = useQuery({
    queryKey: ["requestsByConsumerChart", queryParams],
    queryFn: () => backendClient!.traffic.getRequestsByConsumerChart(queryParams),
    enabled: !!backendClient && !!app,
  });

  let chart;
  let chartHeight = 120;
  if (query.isSuccess) {
    const primaryColor = getColor("primary");
    const secondaryColor = getColor("secondary");
    const dangerColor = getColor("danger");
    const colorMap = new Map([
      ["Successful", primaryColor],
      ["Client error", "#dca6a6"],
      ["Server error", dangerColor],
    ]);
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      font: {
        family: "DM Sans",
      },
      indexAxis: "y" as const,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: true,
            color: "#faf9fb",
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              family: "DM Sans",
            },
            color: "#adb5bd",
          },
          display: true,
          beginAtZero: true,
          precision: 0,
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              family: "DM Sans",
              weight: "bold" as const,
            },
          },
        },
      },
      animation: {
        duration: 0,
      },
      plugins: {
        tooltip: {
          mode: "index" as const,
          callbacks: {
            label: (context: TooltipItem<"bar">) => {
              const statusCodeCounts = (context.dataset.data[context.dataIndex] as any).statusCodeCounts as number[][];
              return statusCodeCounts.map(([statusCode, count]) => {
                return ` ${statusCode} ${getReasonPhrase(statusCode)}: ${count.toLocaleString()}`;
              });
            },
            footer: (context: TooltipItem<"bar">[]) => {
              const total = context.reduce((acc, item) => acc + item.parsed.x, 0);
              return `Total: ${total.toLocaleString()}`;
            },
          },
          itemSort: (context1: TooltipItem<"bar">, context2: TooltipItem<"bar">) => {
            if (context1.dataset.label === context2.dataset.label) {
              return 0;
            } else if (context1.dataset.label === "Successful") {
              return -1;
            } else if (context2.dataset.label === "Successful") {
              return 1;
            } else if (context1.dataset.label === "Client error") {
              return -1;
            } else {
              return 1;
            }
          },
          titleFont: {
            family: "DM Sans",
            size: 12,
            weight: "bold" as const,
          },
          bodyFont: {
            family: "DM Sans",
            size: 12,
            weight: "normal" as const,
          },
          footerFont: {
            family: "DM Sans",
            size: 12,
            weight: "normal" as const,
          },
          footerColor: "#ccc",
        },
        legend: {
          display: false,
        },
      },
      onHover: (event: ChartEvent, elements: ActiveElement[]) => {
        if (event.native?.target && event.native.target instanceof HTMLElement) {
          event.native.target.style.cursor = elements.length > 0 ? "pointer" : "default";
        }
      },
      onClick: (event: ChartEvent, elements: ActiveElement[], chart: ChartJS) => {
        if (event.native) {
          const res = chart.getElementsAtEventForMode(event.native, "y", { intersect: true }, true);
          if (res.length > 0) {
            const datasetIndex = res[0].datasetIndex;
            const index = res[0].index;
            const consumerId = query.data[datasetIndex].consumer_ids[index];
            setConsumerId(consumerId);
          }
        }
      },
    };
    const chartData = {
      datasets: query.data.map((dataset) => {
        const color = colorMap.get(dataset.response_status) || secondaryColor;
        return {
          label: dataset.response_status,
          data: dataset.consumer_names.map((consumer_name, index) => ({
            y: consumer_name,
            x: dataset.request_counts[index],
            statusCodeCounts: dataset.status_code_counts[index],
          })),
          backgroundColor: color,
          hoverBackgroundColor: rgba(color, 0.8),
        };
      }),
    };
    if (chartData.datasets.every((dataset) => dataset.data.length === 0)) {
      return <div className="text-center py-2">No requests by identified consumers in the selected period.</div>;
    } else {
      const uniqueConsumerCount = new Set(query.data?.flatMap((item) => item.consumer_names)).size;
      chartHeight = 40 + Math.min(uniqueConsumerCount, 10) * 40;
      chart = <Bar data={chartData} options={chartOptions} />;
    }
  }

  return <ChartContainer height={chartHeight}>{chart}</ChartContainer>;
}

export default memo(RequestsByConsumerBarChart);
